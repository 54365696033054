/**
 * getKeyLabel: Return key label
 * @param {string} key
 * @param {object} options
 */
function getKeyLabel(key, { rule }) {
  const cleanField = key.replace("triggered_", "");
  return (
    (rule.meta && rule.meta[`${cleanField}_label`]) ||
    (rule.meta && rule.meta[`${cleanField}`]) ||
    rule[cleanField]
  );
}

/**
 * applyAggregation: Aggregate values
 * @param {string} aggregation
 * @param {number} v1
 * @param {number} v2
 */
const applyAggregation = (aggregation = "sum", v1, v2) => {
  switch (aggregation.toLowerCase()) {
    case "max":
      return Math.max(v1, v2);
    case "max_abs":
      return Math.max(Math.abs(v1), Math.abs(v2));
    case "min":
      return Math.min(v1, v2);
    case "min_abs":
      return Math.min(Math.abs(v1), Math.abs(v2));
    case "sum":
      return v1 + v2;
    case "sum_abs":
      return Math.abs(v1) + Math.abs(v2);
    default:
      return v1 + v2;
  }
};

export { getKeyLabel, applyAggregation };
